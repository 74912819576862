<template>
  <v-container fluid class="mt-8">


    <v-row>
      <v-col cols="3">
        <v-menu v-model="startDateDateDialog" :close-on-content-click="false" :nudge-right="40"
          transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="startDate" :label="$t('common.startAt')" prepend-icon="mdi-calendar" readonly
              v-bind="attrs" v-on="on" />
          </template>
          <v-date-picker v-model="startDate" @input="startDateDateDialog = false" />
        </v-menu>
      </v-col>
      <v-col cols="3">
        <v-menu v-model="endDateDateDialog" :close-on-content-click="false" :nudge-right="40"
          transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="endDate" :label="$t('common.endAt')" prepend-icon="mdi-calendar" readonly
              v-bind="attrs" v-on="on" />
          </template>
          <v-date-picker v-model="endDate" @input="endDateDateDialog = false" />
        </v-menu>
      </v-col>
      <v-col cols="1">
        <v-card-text>
          {{ dateRange.length + "日" }}
        </v-card-text>
      </v-col>
      <v-col cols="2">
        <v-btn dark :loading="loading" @click="onSearchPressed">
          {{ $t('statistic.btn_search') }}
        </v-btn>
      </v-col>

      <v-col cols="2">
        <v-btn dark :loading="excelLoading">
          <JsonExcel name="PlaysmartStatistic.xls" :fetch="downoloadExcel">
            {{ $t('statistic.export_data') }}
          </JsonExcel>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-select :items="categoryList" v-model="categorySelected" @change="changeCategory" item-value="_id"
          :item-text="(this.lang == 'tc') ? 'nameZh' : 'nameEng'" label="Category" />
      </v-col>
      <v-col cols="4">
        <v-select :items="typeList" v-model="typeSelected" @change="changeType" item-value="_id"
          :item-text="(this.lang == 'tc') ? 'nameZh' : 'nameEng'" label="Type" />
      </v-col>
      <v-col cols="3">
        <multiselect closeOnSelect v-model="searchByArea" :options="handledAreaList" group-label="region"
          group-values="location" :multiple="false" :group-select="false" :placeholder="$t('common.area')"
          track-by="alias" :label="(lang == 'tc') ? 'nameZh' : 'nameEng'" @input="onAreaChanged">
          <span slot="noResult">{{ $t('global_alert.area_no_result') }}</span>
        </multiselect>
      </v-col>

    </v-row>
    <div style="height: 50px;"></div>
    <v-row>
      <v-col cols="2">
        <v-card>
          <v-card-title>
            {{ $t('statistic.totalBrowse') }}
          </v-card-title>
          <v-card-text class="d-flex justify-end ">
            <h2>
              {{ totalBrowse }}
            </h2>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="2">
        <v-card>
          <v-card-title>
            {{ $t('statistic.totalChat') }}
          </v-card-title>
          <v-card-text class="d-flex justify-end ">
            <h2>
              {{ totalChat }}
            </h2>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="2">
        <v-card>
          <v-card-title>
            {{ $t('statistic.totalOrderCreated') }}
          </v-card-title>
          <v-card-text class="d-flex justify-end ">
            <h2>
              {{ totalOrderCreated }}
            </h2>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="2">
        <v-card>
          <v-card-title>
            {{ $t('statistic.totalOrderConfirmed') }}
          </v-card-title>
          <v-card-text class="d-flex justify-end ">
            <h2>
              {{ totalOrderConfirmed }}
            </h2>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="2">
        <v-card>
          <v-card-title>
            {{ $t('statistic.totalOrderAmount') }}
          </v-card-title>
          <v-card-text class="d-flex justify-end ">
            <h2>
              {{ totalOrderAmount }}
            </h2>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <div style="height: 50px;"></div>
    <v-data-table mobile-breakpoint=0 class="elevation-4" :loading="loading" :headers="i18nHeader" :items="statList"
      :options.sync="options" :server-items-length="totalStat" :custom-sort="customSort" :hide-default-footer="true">
      <template v-slot:item="{ item }">
        <tr>

          <td v-text="item.merchant" />
          <td>{{ item.merchantBrowse }}</td>
          <td>{{ item.merchantChat }}</td>
          <td v-text="item.service" />
          <td>{{ item.serviceBrowse }}</td>
          <td>{{ item.serviceChat }}</td>
          <td>{{ item.totalOrderCreated }}</td>
          <td>{{ item.totalOrderConfirmed }}</td>
          <td>{{ item.totalOrderAmount }}</td>

        </tr>
      </template>
    </v-data-table>


  </v-container>
</template>
  
<script>
import { mapMutations, mapState } from 'vuex'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import JsonExcel from "vue-json-excel"
import api from '@/api'

export default {
  components: {
    Multiselect,
    JsonExcel,
  },
  mounted() {
    this.getAllCategories()
    this.getAllAreas()
  },
  computed: {
    ...mapState(['lang']),
    i18nHeader() {
      let result = this.headers

      result.map(item => {
        item.text = this.$t(item.i18n)
      })

      return result
    },
    handledAreaList() {
      let result = []
      if (this.areasList && this.areasList != '') {
        let areaArr = this.areasList['area']
        let location = this.areasList['subArea']

        areaArr.map(item => {
          result.push({
            'region': item[(this.lang == 'tc') ? 'nameZh' : 'nameEng'],
            'location': location[item.alias]
          })
        })
      }
      return result
    },
  },
  watch: {
    searchByArea: {
      handler() {
        this.handledAreaChanged()
      }
    },
    options: {
      handler() {
        this.sortOption()
      }
    },
  },
  methods: {
    ...mapMutations(['PUSH_GLOBAL_SNACK_MESSAGES']),
    async onSearchPressed() {
      var validated = true;
      if (this.startDate == null || this.endDate == null) {
        validated = false;
      } else {
        var started = new Date(this.startDate);
        var ended = new Date(this.endDate);
        var current = new Date();
        if (current.getTime() + 8 * 60 * 60 * 1000 < ended.getTime()) validated = false;
        if (ended.getTime() < started.getTime()) validated = false;
      }



      if (validated == false) {
        this.PUSH_GLOBAL_SNACK_MESSAGES({
          message: this.$t('statistic.warning_date_range'),
          icon: 'mdi-close',
        })

      } else {
        this.loading = true;
        if (Object.keys(this.merchantConverted).length == 0) {
          await this.getAllServices();
        }

        this.processSearch();
      }
    },
    async processSearch() {
      var dates = this.dateArray(this.startDate, this.endDate)
      var query = {
        "dates": dates,
      }
      if (dates.length == 0) {
        this.updateTable();
      } else {
        try {
          let result = await api.post('/counter/dailystat', query)

          if (result) {
            this.statArray.push(...result.data);
            this.existDate.push(...dates);
            //console.log(this.statArray);
            this.loading = false;
            this.updateTable();
          }


        } catch (error) {
          console.log(error);
          this.PUSH_GLOBAL_SNACK_MESSAGES({
            message: error.response.data.message,
            icon: 'mdi-close',
          })
        }
      }

    },
    updateTable() {
      var merchantpage = 0;
      var servicepage = 0;
      var merchantchat = 0;
      var servicechat = 0;
      var ordercreated = 0;
      var orderconfirmed = 0;
      var orderamount = 0;

      var merchantJson = {};
      var serviceJson = {};

      this.statArray.forEach(e => {
        //Check current range
        if (this.dateRange.indexOf(e["date"]) != -1) {
          var isToAdd = true;
          if (this.areaSelected != "") {
            if (e["area"] != this.areaSelected) isToAdd = false;
          }
          if (this.categorySelected != "") {
            if (e["categoryId"] != this.categorySelected) isToAdd = false;
          }
          //console.log(isToAdd)

          if (isToAdd) {
            //Add to total
            if (e["type"] == "merchantpage") merchantpage = merchantpage + e["count"]
            if (e["type"] == "servicepage") servicepage = servicepage + e["count"]
            if (e["type"] == "merchantchat") merchantchat = merchantchat + e["count"]
            if (e["type"] == "servicechat") servicechat = servicechat + e["count"]
            if (e["type"] == "ordercreated") ordercreated = ordercreated + e["count"]
            if (e["type"] == "orderconfirmed") orderconfirmed = orderconfirmed + e["count"]
            if (e["type"] == "orderamount") orderamount = orderamount + e["count"]
            //Count single
            var mid = e["merchantId"]
            var sid = e["serviceId"]
            if (merchantJson[mid] == null) {
              merchantJson[mid] = {
                "merchantBrowse": 0,
                "merchantChat": 0,
              };
            }
            if (sid) {
              if (serviceJson[sid] == null) {

                serviceJson[sid] = {
                  "merchant": this.merchantConverted[mid]["name"] ?? "",
                  "area": this.merchantConverted[mid]["area"] ?? "",
                  "category": this.merchantConverted[mid]["category"] ?? "",
                  "merchantBrowse": 0,
                  "merchantChat": 0,
                  "service": this.serviceConverted[sid]["name"] ?? "",
                  "serviceBrowse": 0,
                  "serviceChat": 0,
                  "totalOrderCreated": 0,
                  "totalOrderConfirmed": 0,
                  "totalOrderAmount": 0,
                  "mid": mid
                };
              }
              if (e["type"] == "servicepage") serviceJson[sid]["serviceBrowse"] = serviceJson[sid]["serviceBrowse"] + e["count"]
              if (e["type"] == "servicechat") serviceJson[sid]["serviceChat"] = serviceJson[sid]["serviceChat"] + e["count"]
              if (e["type"] == "ordercreated") serviceJson[sid]["totalOrderCreated"] = serviceJson[sid]["totalOrderCreated"] + e["count"]
              if (e["type"] == "orderconfirmed") serviceJson[sid]["totalOrderConfirmed"] = serviceJson[sid]["totalOrderConfirmed"] + e["count"]
              if (e["type"] == "orderamount") serviceJson[sid]["totalOrderAmount"] = serviceJson[sid]["totalOrderAmount"] + e["count"]
            } else {

              if (e["type"] == "merchantpage") merchantJson[mid]["merchantBrowse"] = merchantJson[mid]["merchantBrowse"] + e["count"]
              if (e["type"] == "merchantchat") merchantJson[mid]["merchantChat"] = merchantJson[mid]["merchantChat"] + e["count"]
            }
          }


        }
      });

      this.totalBrowse = merchantpage + servicepage;
      this.totalChat = merchantchat + servicechat;
      this.totalOrderCreated = ordercreated;
      this.totalOrderConfirmed = orderconfirmed;
      this.totalOrderAmount = orderamount;

      var array = [];
      if (this.typeSelected == "service") {
        Object.keys(serviceJson).forEach(key => {
          var single = JSON.parse(JSON.stringify(serviceJson[key]));
          var mid = single["mid"];
          single["merchantBrowse"] = merchantJson[mid]["merchantBrowse"];
          single["merchantChat"] = merchantJson[mid]["merchantChat"];
          delete single["mid"];
          array.push(single)
        });
      }
      if (this.typeSelected == "merchant") {
        Object.keys(merchantJson).forEach(mid => {
          var single = merchantJson[mid];

          var result = {
            "merchant": this.merchantConverted[mid]["name"],
            "area": this.merchantConverted[mid]["area"],
            "category": this.merchantConverted[mid]["category"],
            "merchantBrowse": single["merchantBrowse"],
            "merchantChat": single["merchantChat"],
            "service": "",
            "serviceBrowse": this.sumArrayValues(serviceJson, mid, "serviceBrowse"),
            "serviceChat": this.sumArrayValues(serviceJson, mid, "serviceChat"),
            "totalOrderCreated": this.sumArrayValues(serviceJson, mid, "totalOrderCreated"),
            "totalOrderConfirmed": this.sumArrayValues(serviceJson, mid, "totalOrderConfirmed"),
            "totalOrderAmount": this.sumArrayValues(serviceJson, mid, "totalOrderAmount"),
          };
          array.push(result)
        });
      }


      this.statList = array; console.log(array)
      this.totalStat = array.length;

      this.sortOption();

      //console.log(serviceJson);
    },
    sumArrayValues(json, id, field) {
      var sum = 0;
      Object.keys(json).forEach(key => {
        var single = json[key];
        if (single["mid"] == id) {
          sum = sum + single[field];
        }
      });
      return sum;
    },
    async getAllAreas() {
      try {
        let result = await api.get('/setting/areas')

        if (result) {
          this.areasList = result.data
        }
      } catch (error) {
        this.PUSH_GLOBAL_SNACK_MESSAGES({
          message: error.response.data.message,
          icon: 'mdi-close',
        })
      }
    },
    async getAllServices() {
      try {

        var result = await api.get('/services/adminlist');
        if (result) {
          this.serviceList = result.data;

          var jsonM = {};
          var jsonS = {};
          this.serviceList.forEach(e => {
            //console.log(e)
            var sid = e["_id"];
            var mid = e["merchantId"]["_id"];
            if (jsonS[sid] == null) {
              jsonS[sid] = {
                "name": e["name"],
                "merchantId": mid,
              }
            }

            if (jsonM[mid] == null) {
              jsonM[mid] = {
                "name": e["merchantId"]["name"],
                "category": e["merchantId"]["category"],
                "area": e["merchantId"]["area"],
              }
            }
          });
          this.merchantConverted = jsonM;
          this.serviceConverted = jsonS;

        }
        //console.log(this.merchantList)

      } catch (error) {
        console.log(error);
        this.PUSH_GLOBAL_SNACK_MESSAGES({
          message: error.response.data.message,
          icon: 'mdi-close',
        })
      }
    },
    async getAllCategories() {
      let apiTarget = (this.permissions == 'admin') ? '/category' : '/category/published'

      try {
        let result = await api.get(apiTarget)
        if (result) {
          this.categoryList = result.data;
          this.categoryList.unshift({
            "_id": "",
            "nameZh": "所有",
            "nameEng": "All"
          })
        }

      } catch (error) {
        this.PUSH_GLOBAL_SNACK_MESSAGES({
          message: error.response.data.message,
          icon: 'mdi-close',
        })
      }
    },
    handledCategories(category) {
      let findedCat = category

      if (category && this.categoryList.length > 0) {
        this.categoryList.map(item => {
          if (item._id == category) findedCat = item[(this.lang == 'tc') ? 'nameZh' : 'nameEng']
        })
      }

      return findedCat
    },
    handledAreaChanged() {
      if (this.searchByArea) {
        //this.areaSelected = this.searchByArea.alias;
        //console.log(this.areaSelected);
        //this.updateTable();
      }
    },
    onAreaChanged(single) {
      console.log("single " + JSON.stringify(single));
      if (single) {
        this.areaSelected = single["alias"];
      } else {
        this.areaSelected = "";
      }

      if (this.existDate.length > 0) {
        this.updateTable();
      }
    },
    changeCategory(id) {
      console.log(id);
      this.categorySelected = id;
      if (this.existDate.length > 0) {
        this.updateTable();
      }

    },
    changeType(id) {
      console.log(id);
      this.typeSelected = id;
      if (this.existDate.length > 0) {
        this.updateTable();
      }

    },
    dateArray(start, end) {
      var starttime = new Date(start);
      var endtime = new Date(end);
      var days = Math.ceil((endtime.getTime() - starttime.getTime()) / (24 * 60 * 60 * 1000));
      var array = [];
      var range = [];
      for (let i = 0; i < days + 1; i++) {
        var newday = new Date(starttime.getTime() + i * 24 * 60 * 60 * 1000)
        var newdate = newday.toISOString().substring(0, 10);
        range.push(newdate);
        if (this.existDate.indexOf(newdate) == -1) {
          array.push(newdate)
        }

      }
      this.dateRange = range;
      //console.log(array);
      return array;
    },
    customSort(
      items,
      sortBy,
      sortDesc,
      locale,
      customSorters
    ) {
      /*
      :sort-by="['name','email','phone','createdAt', 'endAt', 'status']"
      :sort-desc="[false, true]"
      */
      console.log(sortBy);
      console.log(customSorters);
      console.log(sortDesc);
      return items.sort((a, b) => a.name.localeCompare(b.name, locale));

    },
    sortOption() {
      var { sortBy, sortDesc } = this.options;
      //console.log(this.options);
      if (sortBy.length === 1 || sortDesc.length === 1) {
        this.statList = this.statList.sort((a, b) => {
          const sortA = a[sortBy[0]]
          const sortB = b[sortBy[0]]

          if (sortDesc[0]) {
            if (sortA < sortB) return 1
            if (sortA > sortB) return -1
            return 0
          } else {
            if (sortA < sortB) return -1
            if (sortA > sortB) return 1
            return 0
          }
        })
      }
    },
    handledCategoryPlan(planID) {
      let result = planID

      if (planID && this.categoryList.length > 0) {
        let found = this.categoryList.find(item => item._id == planID)
        if (found) result = found[(this.lang == 'tc') ? 'nameZh' : 'nameEng']
      }

      return result
    },
    async downoloadExcel() {
      this.excelLoading = true
      let resultArr = []
      let tmpObj = {}

      this.statList.forEach(e => {
        tmpObj = JSON.parse(JSON.stringify(e));
        tmpObj["category"] = this.handledCategoryPlan(tmpObj["category"])
        resultArr.push(tmpObj)
      })
      this.excelLoading = false
      return resultArr;
    },


  },
  data: () => ({
    headers: [
      { i18n: 'statistic.merchant', value: 'merchant' },
      { i18n: 'statistic.merchantBrowse', value: 'merchantBrowse' },
      { i18n: 'statistic.merchantChat', value: 'merchantChat' },
      { i18n: 'statistic.service', value: 'service' },
      { i18n: 'statistic.serviceBrowse', value: 'serviceBrowse' },
      { i18n: 'statistic.serviceChat', value: 'serviceChat' },
      { i18n: 'statistic.totalOrderCreated', value: 'totalOrderCreated' },
      { i18n: 'statistic.totalOrderConfirmed', value: 'totalOrderConfirmed' },
      { i18n: 'statistic.totalOrderAmount', value: 'totalOrderAmount' },
    ],
    loading: false,
    categoryList: [],
    typeList: [
      { "_id": "service", "nameZh": "活動頁", "nameEng": "Service" },
      { "_id": "merchant", "nameZh": "商戶頁", "nameEng": "Merchant" },
    ],
    areasList: [],
    categorySelected: "",
    typeSelected: "service",
    searchByArea: null,
    areaSelected: "",
    endDateDateDialog: false,
    endDate: null,
    startDateDateDialog: false,
    startDate: null,
    excelLoading: false,
    totalBrowse: 0,
    totalChat: 0,
    totalOrderCreated: 0,
    totalOrderConfirmed: 0,
    totalOrderAmount: 0,
    existDate: [],
    dateRange: [],
    statArray: [],
    merchantList: [],
    serviceList: [],
    merchantConverted: {},
    serviceConverted: {},
    options: {},
    statList: [
    ],
    totalStat: 0,
  })
}
</script>

